<template>
  <layout :loading="loading">
    <template v-slot:content>
      <t-alert variant="info" class="my-2" :show="showSuccessMessage">
        {{ $t("free_days_saved") }}
      </t-alert>
      <div class="flex justify-center mt-4 mb-2 items-center w-full">
        <router-link
          :to="`/free-day/${previousYearMonth}`"
          class="text-lg text-red font-semibold block text-blue-500"
        >
          &nbsp;<i class="fas fa-caret-left"></i>
        </router-link>
        <span class="mx-3">
          {{ `${currentMonthTranslation} ${selectedYear}` }}
        </span>
        <router-link
          :to="`/free-day/${nextYearMonth}`"
          class="text-lg text-red font-semibold block text-blue-500"
        >
          &nbsp;<i class="fas fa-caret-right"></i>
        </router-link>
      </div>
      <form class="overflow-auto">
        <table class="mx-auto">
          <thead>
            <th
              class="text-sm py-3 px-8 border-t border-b border-gray-300"
              v-for="(dayName, index) in dayNames"
              :key="index"
            >
              {{ dayName }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(week, index) in groupedDays" :key="index">
              <td
                class="py-2 px-8 border-t border-b border-gray-300 text-center"
                v-for="(day, dayIndex) in week"
                :key="dayIndex"
              >
                <span class="text-sm mb-2">{{ day }}</span>
                <div class="flex flex-col items-center h-14">
                  <template v-if="day && !isDatePlanned(day)">
                    <t-select
                      class="padding-none text-xs w-14 h-6 text-xs mb-1"
                      v-model="payload[day]['from']"
                      :options="timeSlotOptions.start"
                      value-attribute="value"
                      text-attribute="name"
                    ></t-select>
                    <t-select
                      class="padding-none text-xs w-14 h-6 text-xs"
                      v-model="payload[day]['until']"
                      :options="timeSlotOptions.end"
                      value-attribute="value"
                      text-attribute="name"
                    ></t-select>
                  </template>
                  <small v-if="isDatePlanned(day)" class="text-xs">{{
                    $t("sheduled")
                  }}</small>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <t-button
        class="mx-auto w-24 m-4 text-sm"
        variant="primary"
        :disabled="loading"
        @click="onSubmit"
        >{{ $t("edit_save") }}</t-button
      >
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import {
  getMonthTranslation,
  getTimeSlotOptions,
  getEndTimeDay,
} from "@/utils/misc";
import moment from "@/utils/moment-utc";
import * as _ from "lodash";
import { getters, actions } from "@/constants/state";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Layout },
  props: ["yearMonth"],
  data: () => ({
    showSuccessMessage: false,
    loading: false,
  }),
  async mounted() {
    this.loading = true;

    const yearMonth = moment(this.yearMonth, "YYYY-MM");
    try {
      let payload = {
        from: yearMonth.startOf("month").format("YYYY-MM-DD"),
        until: yearMonth.endOf("month").format("YYYY-MM-DD"),
        userId: this.userId,
      };

      await Promise.all([
        this.getUserFreeDays(payload),
        this.getFullSchedules(payload),
      ]);
    } catch (error) {
      console.log("DEBUG: error", error);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      upsertUserFreeDays: actions.FREEDAYS_UPSERT_USER_FREE_DAYS_ACTION,
      getUserFreeDays: actions.FREEDAYS_FETCH_USER_FREE_DAYS_ACTION,
      getFullSchedules: actions.FULLSCHEDULES_FETCH_ACTION,
    }),
    isDatePlanned(day) {
      return !!_.find(
        this.fullSchedules,
        (sched) =>
          moment(sched.start).format("YYYY-MM-DD") ===
          `${this.yearMonth}-${day}`
      );
    },
    async onSubmit() {
      this.loading = true;
      this.showSuccessMessage = false;

      try {
        const filtered = _.reduce(
          this.payload,
          (payload, time, day) => {
            if (!time.from || !time.until) {
              return payload;
            }

            return [
              ...(payload || []),
              {
                ...time,
                from: `${this.yearMonth}-${day} ${time.from}:00`,
                until: getEndTimeDay(
                  `${this.yearMonth}-${day}`,
                  time.from,
                  time.until
                ),
              },
            ];
          },
          []
        );

        await this.upsertUserFreeDays({
          userId: this.userId,
          locationId: this.locationId,
          timeSlots: filtered,
          from: moment(this.yearMonth, "YYYY-MM")
            .startOf("month")
            .format("YYYY-MM-DD"),
          until: moment(this.yearMonth, "YYYY-MM")
            .endOf("month")
            .format("YYYY-MM-DD"),
        });

        this.showSuccessMessage = true;
      } catch (error) {
        console.error("DEBUG: onSubmit error", error);
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      fullSchedules: getters.FULLSCHEDULES_GETTER,
      userId: getters.AUTH_USER_ID_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
      userFreeDays: getters.FREEDAYS_USER_FREE_DAYS_GETTER,
    }),
    selectedYear() {
      return moment(this.yearMonth, "YYYY-MM").format("YYYY");
    },
    timeSlotOptions() {
      const timeSlots = getTimeSlotOptions(false);
      return {
        start: [
          {
            name: "",
            value: null,
          },
          ..._.map(timeSlots.start, (label, time) => ({
            name: label,
            value: time,
          })),
        ],
        end: [
          {
            name: "",
            value: null,
          },
          ..._.map(timeSlots.end, (label, time) => ({
            name: label,
            value: time,
          })),
        ],
      };
    },
    payload() {
      const flatted = _.flatten(this.groupedDays);
      return _.reduce(
        flatted,
        (days, day) => {
          const freeDay = _.find(
            this.userFreeDays,
            (freeDay) =>
              `${this.yearMonth}-${day}` ===
              moment(freeDay.from).format("YYYY-MM-DD")
          );
          return {
            ...days,
            [day]: {
              id: _.get(freeDay, "id"),
              from: freeDay ? moment(freeDay.from).format("HH:mm") : null,
              until: freeDay ? moment(freeDay.until).format("HH:mm") : null,
            },
          };
        },
        null
      );
    },
    previousYearMonth() {
      return moment(this.yearMonth, "YYYY-MM")
        .subtract(1, "months")
        .format("YYYY-MM");
    },
    nextYearMonth() {
      return moment(this.yearMonth, "YYYY-MM")
        .add(1, "months")
        .format("YYYY-MM");
    },
    currentMonthTranslation() {
      return getMonthTranslation(
        moment(this.yearMonth, "YYYY-MM").format("MM")
      );
    },
    dayNames() {
      return [
        this.$i18n.t("monday_short"),
        this.$i18n.t("tuesday_short"),
        this.$i18n.t("wednesday_short"),
        this.$i18n.t("thursday_short"),
        this.$i18n.t("friday_short"),
        this.$i18n.t("saturday_short"),
        this.$i18n.t("sunday_short"),
      ];
    },
    groupedDays() {
      const yearMonth = moment(this.yearMonth, "YYYY-MM");
      const days = Array.from(Array(yearMonth.daysInMonth()), (_, i) =>
        `${i + 1}`.padStart(2, 0)
      );
      const startOfMonth = yearMonth.startOf("month").isoWeekday();
      for (let day = 1; day < startOfMonth; day++) {
        days.unshift(null);
      }
      return _.chunk(days, 7);
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-none {
  padding: 0 !important;
}
</style>
